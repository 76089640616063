import { createContext } from 'react'

import { WebsocketsActions } from './reducer'
import { WebsocketsState } from './types'

export const WebsocketsDefaultState = {
  sendMessage: () => {},
  isConnected: false,
  payments: [],
}

export const WebsocketsContext = createContext<WebsocketsState>(WebsocketsDefaultState)
export type WebsocketsDispatch = (action: WebsocketsActions) => void
export const DispatchContext = createContext<WebsocketsDispatch | undefined>(undefined)
