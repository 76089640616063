import { WebsocketsDispatch } from './context'
import { PaymentOrder } from './types'

export function addPaymentsOrder(dispatch: WebsocketsDispatch) {
  return (payments: PaymentOrder[]) => dispatch({ type: 'ADD_PAYMENTS_ORDER', payments })
}

export function clearPaymentsOrder(dispatch: WebsocketsDispatch) {
  return () => dispatch({ type: 'CLEAR_PAYMENTS_ORDER' })
}
