import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'

import { WEBSOCKET_API_URL } from '../../config'
import { getAccessToken } from '../../utils/store'
import { useAppStore } from '../App'
import { useAuth } from '../Auth'
import { addPaymentsOrder, clearPaymentsOrder } from './actions'
import { DispatchContext, WebsocketsContext, WebsocketsDefaultState } from './context'
import reducer from './reducer'
import { WebsocketMessage } from './types'

const RECONNECTION_INITIAL_TIME = 3000
const MAX_RECONNECTION_TIME = 120000

export const WebsocketsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, WebsocketsDefaultState)

  const {
    state: { isAuth },
  } = useAuth()
  const {
    state: { currentStore },
  } = useAppStore()

  const [isConnected, setIsConnected] = useState(false)
  const [reconnectionTime, setReconnectionTime] = useState(RECONNECTION_INITIAL_TIME)

  const wsRef = useRef<WebSocket | null>(null)

  const sendMessage = useCallback(
    (message: WebsocketMessage) => {
      if (wsRef.current && isConnected) {
        wsRef.current.send(JSON.stringify(message))
      }
    },
    [isConnected],
  )

  const setupWebSocket = useCallback(async () => {
    const accessToken = getAccessToken()
    if (!accessToken || !currentStore?.id) {
      return () => {}
    }

    const url = `${WEBSOCKET_API_URL}?accessToken=${accessToken}&customerId=${currentStore.id}`
    const websocket = new WebSocket(url)

    websocket.onopen = () => {
      setIsConnected(true)
      setReconnectionTime(RECONNECTION_INITIAL_TIME)
    }

    websocket.onmessage = (event) => {
      const data: WebsocketMessage = JSON.parse(event.data)
      if (data.body?.payments) {
        addPaymentsOrder(dispatch)(data.body.payments)
      }
    }

    websocket.onerror = (error) => {
      console.error('WebSocket error: ', error)
      setIsConnected(false)
    }

    websocket.onclose = () => {
      setIsConnected(false)
      setTimeout(setupWebSocket, reconnectionTime)
      setReconnectionTime((prevTime) => Math.min(prevTime * 2, MAX_RECONNECTION_TIME))
    }

    wsRef.current = websocket

    return () => websocket.close()
  }, [currentStore?.id, reconnectionTime])

  useEffect(() => {
    let cleanupFunction: () => void = () => {}

    const initializeWebSocket = async () => {
      if (isAuth) {
        cleanupFunction = await setupWebSocket()
      } else if (wsRef.current) {
        wsRef.current.close()
        wsRef.current = null
      }
    }

    initializeWebSocket()

    return () => {
      cleanupFunction()
    }
  }, [isAuth, setupWebSocket])

  return (
    <WebsocketsContext.Provider value={{ ...state, sendMessage, isConnected }}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </WebsocketsContext.Provider>
  )
}

export function useWebsockets() {
  const state = React.useContext(WebsocketsContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useWebsockets must be used within a WebsocketsProvider')
  }

  return {
    state,
    addPaymentsOrder: addPaymentsOrder(dispatch),
    clearPaymentsOrder: clearPaymentsOrder(dispatch),
  }
}
