import { AtLoader } from '@embonor/atomic-library'
import React from 'react'

interface Props {
  size?: number
  className?: string
}
const LoadingIndicator = ({ size = 24, className = '' }: Props) => {
  return (
    <div className={className}>
      <AtLoader size={size} />
    </div>
  )
}

export default LoadingIndicator
