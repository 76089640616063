import APP from './../../package.json'

export const COUNTRY = process.env.GATSBY_COUNTRY
export const STAGE = process.env.GATSBY_STAGE

export const EMBONOR_AUTHENTICATION_SERVICE = process.env.GATSBY_EMBONOR_AUTHENTICATION_SERVICE
export const EMBONOR_BACKEND_FOR_FRONTEND = process.env.GATSBY_EMBONOR_BACKEND_FOR_FRONTEND
export const APP_VERSION = APP.version
export const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID || ''
export const ALGOLIA_INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME || ''
export const ALGOLIA_SUPPORT_QUESTIONS_INDEX_NAME = process.env.GATSBY_ALGOLIA_SUPPORT_QUESTIONS_INDEX_NAME || ''
export const ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY || ''
export const CONTENTFUL_SPACE_ID = process.env.GATSBY_CONTENTFUL_SPACE_ID
export const CONTENTFUL_CDA_TOKEN = process.env.GATSBY_CONTENTFUL_CDA_TOKEN
export const CONTENTFUL_ENV = process.env.GATSBY_CONTENTFUL_ENV
export const UNLEASH_URL = process.env.GATSBY_UNLEASH_URL || ''
export const UNLEASH_CLIENT_KEY = process.env.GATSBY_UNLEASH_CLIENT_KEY || ''
export const UNLEASH_APP_NAME = process.env.GATSBY_UNLEASH_APP_NAME || ''
export const UNLEASH_ENV = process.env.GATSBY_UNLEASH_ENV || ''
export const SURVEY_1 = Number(process.env.GATSBY_SURVEY_1) || 0
export const SURVEY_2 = Number(process.env.GATSBY_SURVEY_2) || 0
export const MINIMUM_ICE_CREAM_PRODUCTS = Number(process.env.GATSBY_MINIMUM_ICE_CREAM_PRODUCTS) || 3
export const WEBSOCKET_API_URL = process.env.GATSBY_WEBSOCKET_API_URL

export const SENTRY_DSN = process.env.GATSBY_SENTRY_DSN
