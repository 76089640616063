import { PaymentOrder, WebsocketsState } from './types'

type ADD_PAYMENTS_ORDER = { type: 'ADD_PAYMENTS_ORDER'; payments: PaymentOrder[] }
type CLEAR_PAYMENTS_ORDER = { type: 'CLEAR_PAYMENTS_ORDER' }

export type WebsocketsActions = ADD_PAYMENTS_ORDER | CLEAR_PAYMENTS_ORDER

function reducer(state: WebsocketsState, action: WebsocketsActions): WebsocketsState {
  switch (action.type) {
    case 'ADD_PAYMENTS_ORDER': {
      return { ...state, payments: [...state.payments, ...action.payments] }
    }

    case 'CLEAR_PAYMENTS_ORDER': {
      return { ...state, payments: [] }
    }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
